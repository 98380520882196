import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Spinner } from 'components';
import { DateTime } from 'luxon';
import { Fragment } from 'react';
import { useQuery } from 'react-query';
import { transactionService } from 'services';
import { formatBalance } from 'utils/common';

const TabDeposit = () => {
  const { data, isFetching } = useQuery(['transactionService.getDeposit'], () => transactionService.getDeposit());

  return (
    <Spinner loading={isFetching}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Thời gian</TableCell>
              <TableCell align='right'>Số tiền</TableCell>
              <TableCell align='right'>Trạng thái</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.deposits.map((item) => {
              const isNote = Boolean(item.note);
              return (
                <Fragment key={item.id}>
                  <TableRow>
                    <TableCell className={isNote ? 'border-b-0' : ''}>
                      {DateTime.fromISO(item.createdAt).toFormat('HH:ss dd/MM/yyyy')}
                    </TableCell>
                    <TableCell className={isNote ? 'border-b-0' : ''} align='right'>
                      <div className='text-success'>{formatBalance(item.amount)}</div>
                    </TableCell>
                    <TableCell className={isNote ? 'border-b-0' : ''} align='right'>
                      {item.status === 'SUCCESS' ? (
                        <div className='text-lime'>Đã nạp</div>
                      ) : (
                        <div className='text-error'>Lỗi</div>
                      )}
                    </TableCell>
                  </TableRow>
                  {isNote && (
                    <TableRow className='mt-[-4px]'>
                      <TableCell className='pt-0' colSpan={3}>
                        Ghi chú: {item.note}
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <div className='fixed inset-0 top-[unset] bottom-[68px] max-w-[600px] mx-auto z-10'>
        <div className='h-[40px] bg-success-gradient rounded-full mx-2'>
          <div className='h-full flex justify-between items-center px-[16px]'>
            <div className='flex space-x-1'>
              <span>Tổng số đơn:</span>
              <span className='font-bold'>{data?.totalDeposits ?? 0}</span>
            </div>
            <div className='flex space-x-1'>
              <span>Lợi nhuận:</span>
              <span className='font-bold'>{formatBalance(data?.totalDepositAmount)}</span>
            </div>
          </div>
        </div>
      </div>
    </Spinner>
  );
};
export default TabDeposit;
